import {

  FETCH_SALE_ORDERS_SUCCESS,
  FETCH_SALE_ORDERS_STARTED,
  FETCH_SALE_ORDERS_FAILURE,
  ADD_SALE_ORDER_SUCCESS,
  ADD_SALE_ORDER_FAILURE,
  UPDATE_SALE_ORDER_FAILURE,
  UPDATE_SALE_ORDER_SUCCESS,
  DELETE_SALE_ORDER_SUCCESS,
  DELETE_SALE_ORDER_FAILURE,
  FETCH_SALE_ORDER_STARTED,
  FETCH_SALE_ORDER_SUCCESS,
  FETCH_SALE_ORDER_FAILURE,
  RESET_SALE_ORDER_SELECTED,
  SEARCH_SALE_ORDER_STARTED,
  SEARCH_SALE_ORDER_SUCCESS,
  SEARCH_SALE_ORDER_FAILURE,
  UPDATE_SALE_ORDER_STARTED,

} from "../actions/types";

const initialState = {
  saleorders: [],
  isSearching: false,
  isUpdating: false,
  selected: null,
  loading: false,
  error: null,
  paginate: {},
};

const saleOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    //FETCH

    case FETCH_SALE_ORDERS_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_SALE_ORDERS_SUCCESS:
      return {
        ...state,
        saleorders: action.payload.list,
        paginate: action.payload.paginate,
        loading: false,
        isSearching: false,
        error: null
      };

    case FETCH_SALE_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };


    //SELECT AN ORODER
    case FETCH_SALE_ORDER_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_SALE_ORDER_SUCCESS:
      return {
        ...state,
        selected: action.payload.saleorder,
        loading: false,
        error: null
      };

    case FETCH_SALE_ORDER_FAILURE:
      return {
        ...state,
        selected: null,
        loading: false,
        error: action.payload.error
      };


    //SEARCH
    case SEARCH_SALE_ORDER_STARTED:
      return {
        ...state,
        isSearching: true,
        selected: null
      };
    case SEARCH_SALE_ORDER_SUCCESS:

      return {
        ...state,
        saleorders: action.payload.list,
        paginate: action.payload.paginate,
        isSearching: false,
        error: null
      };

    case SEARCH_SALE_ORDER_FAILURE:
      return {
        ...state,
        isSearching: false,
        error: action.payload.error
      };


    //RESET
    case RESET_SALE_ORDER_SELECTED:
      return {
        ...state,
        selected: null,
      };




    //ADD
    case ADD_SALE_ORDER_SUCCESS:
      return {
        ...state,
        saleorders: [action.payload.saleOrder, ...state.saleorders],
        error: null,
        success: action.payload.successMessage
      };

    case ADD_SALE_ORDER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null
      };


    //DELETE
    case DELETE_SALE_ORDER_SUCCESS:
      return {
        ...state,
        saleorders: state.saleorders.filter(sale => sale._id !== action.payload.id),
        error: null,
        success: action.payload.successMessage
      };




    case DELETE_SALE_ORDER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null
      };


    //UPDATE
    case UPDATE_SALE_ORDER_STARTED:
      return {
        ...state,
        error: null,
        isUpdating: true,
      };

    case UPDATE_SALE_ORDER_SUCCESS:
      return {
        ...state,
        error: null,
        isUpdating: false,
        success: action.payload.successMessage
      };


    case UPDATE_SALE_ORDER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isUpdating: false,
        success: null
      };

    default:
      return state;
  }
};

export default saleOrderReducer;
