import {

  FETCH_NCREPORTS_SUCCESS,
  FETCH_NCREPORTS_STARTED,
  FETCH_NCREPORTS_FAILURE,
  FETCH_NCREPORT_SUCCESS,
  FETCH_NCREPORT_STARTED,
  FETCH_NCREPORT_FAILURE,
  SEARCH_NCREPORT_STARTED,
  SEARCH_NCREPORT_SUCCESS,
  SEARCH_NCREPORT_FAILURE,
  ADD_NCREPORT_SUCCESS,
  ADD_NCREPORT_FAILURE,
  UPDATE_NCREPORT_FAILURE,
  UPDATE_NCREPORT_SUCCESS,
  DELETE_NCREPORT_SUCCESS,
  DELETE_NCREPORT_FAILURE,
  RESET_NCREPORT_SELECTED,

} from "../actions/types";

const initialState = {
  ncreports: [],
  loading: false,
  error: null
};

const partReducer = (state = initialState, action) => {
  switch (action.type) {
    //FETCH

    case FETCH_NCREPORTS_STARTED:
      return {
        ...state,
        loading: true,
        ncreports: [],
        paginate: {}
      };
    case FETCH_NCREPORTS_SUCCESS:
      return {
        ...state,
        ncreports: action.payload.ncreports,
        paginate: action.payload.paginate,
        loading: false,
        error: null
      };

    case FETCH_NCREPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };


    //
    case FETCH_NCREPORT_STARTED:
      return {
        ...state,
        loading: true,
        selected: null,
      };

    case FETCH_NCREPORT_SUCCESS:

      return {
        ...state,
        selected: { ...action.payload.data.ncreport, company: action.payload.data.company },
        loading: false,
        error: null
      };

    case FETCH_NCREPORT_FAILURE:
      return {
        ...state,
        selected: null,
        loading: false,
        error: action.payload.error
      };


    //RESET
    case RESET_NCREPORT_SELECTED: {
      return {
        ...state,
        loading: false,
        selected: null,
      };
    }



    //SEARCH
    case SEARCH_NCREPORT_STARTED:
      return {
        ...state,
        loading: true,
        selected: null
      };
    case SEARCH_NCREPORT_SUCCESS:

      return {
        ...state,
        ncreports: action.payload.ncreports,
        loading: false,
        error: null
      };

    case SEARCH_NCREPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    //ADD
    case ADD_NCREPORT_SUCCESS:
      return {
        ...state,
        ncreports: [action.payload.ncreport, ...state.ncreports],
        error: null,
        success: action.payload.successMessage
      };

    case ADD_NCREPORT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null
      };


    //DELETE
    case DELETE_NCREPORT_SUCCESS:
      return {
        ...state,
        ncreports: state.ncreports.filter(ncreport => ncreport._id !== action.payload.id),
        error: null,
        success: action.payload.successMessage
      };




    case DELETE_NCREPORT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null
      };


    //UPDATE
    case UPDATE_NCREPORT_SUCCESS:
      return {
        ...state,
        error: null,
        success: action.payload.successMessage
      };


    case UPDATE_NCREPORT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null
      };

    default:
      return state;
  }
};

export default partReducer;
