import { GRID_VIEW, TABLE_VIEW } from "@redux/actions/types";


const initialState = {
    viewMode: TABLE_VIEW,
};

const viewModeReducer = (state = initialState, action) => {
    switch (action.type) {
        //FETCH

        case TABLE_VIEW:
           
            return {
                ...state,
                viewMode: TABLE_VIEW
            };
        case GRID_VIEW:

            return {
                ...state,
                viewMode: GRID_VIEW
            };

        default:
            return state;
    }
};

export default viewModeReducer;
