import { USER_LOADING, USER_LOADED, AUTH_ERROR } from "./../types";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import axios from "axios";

/*
 * this function to load the user with a specific token given from the frontend
 * in the request header cause JWT is stateless and we have to load the user
 * from the token in every refresh to the website
 * call this function in app.js before any render in useEffect
 */
export const loadUser = () => (dispatch, getState) => {
  dispatch({ type: USER_LOADING });

  const cancelSubscription = onAuthStateChanged(getAuth(), user => {
    if (user) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/user`,
        {
          headers: {
            "Content-type": "application/json",
            "x-auth-token": user.accessToken,
          },
        }
      )
        .then(res => {
          dispatch(userLoadedSuccess({ ...user, ...res.data }));
        })
        .catch(error => {
          cancelSubscription();
          dispatch(userLoadedFailure(error));
          

        });

    } else {
      dispatch(userLoadedFailure())
    }

    return cancelSubscription;

  });



}

const userLoadedSuccess = user => {
  return {
    type: USER_LOADED,
    payload: { user }
  };
};

const userLoadedFailure = error => {
  return {
    type: AUTH_ERROR,
    payload: { error }
  };
};
