import { Image, } from '@chakra-ui/react'
import LogoImg from '../../assets/logo.png';
import { useNavigate } from 'react-router-dom';
export const Logo = ({ ...props }) => {
    const navigate = useNavigate();
    return (<Image
        loading='lazy'
        onClick={() => { navigate('/') }}
          src={LogoImg}
        alt='UPM LOGO'
        boxSize='12em'
        height={'7em'}
        {...props}
        ml={2}
        mt={2}
        
      
      
    />





    )
}