// 1. Import `extendTheme`
import { theme } from '@chakra-ui/pro-theme'
import { extendTheme } from '@chakra-ui/react'

import '@fontsource-variable/inter'




const proTheme = extendTheme(theme)
const extenstion = {
  colors: {
    ...proTheme.colors,
    brand:
    {
      50: '#daf8ff',
      100: '#aee4ff',
      200: '#7fd0fc',
      300: '#4fbef9',
      400: '#22abf6',
      500: '#0992dd',
      600: '#0071ad',
      700: '#00517d',
      800: '#00324e',
      900: '#001120',
    }
  },
  fonts: {
    heading: "'Inter Variable', -apple-system, system-ui, sans-serif",
    body: "'Inter Variable', -apple-system, system-ui, sans-serif",
  },
 
 

 
  
}
export const themeUPM = extendTheme(extenstion, proTheme)