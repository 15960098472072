import { Button, Center, Divider, HStack, Icon, Input, Square, Text, useColorMode, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { FiUploadCloud } from 'react-icons/fi'
import { toast } from 'react-toastify'

const Dropzone = ({
    onDrop = () => { },
    onError = () => { },
    maxSize = 5 * 1024 * 1024,
    types = [
        'image/jpeg',
        'image/png',
        'image/jpg',
        'image/svg',
        'image/gif',
        'image/webp',
        'image/bmp',
    ],
    accept = [
        '.jpg',
    ], ...props }) => {


    const [uploaded_file, setUploadedFile] = useState(null)
    const [dragging, setDragging] = useState(false)
    const colorMode = useColorMode()
    const dragOver = (e) => {
        e.preventDefault()
        setDragging(true)
    }
    const dragEnter = (e) => {
        e.preventDefault()
        setDragging(true)
    }
    const dragLeave = (e) => {
        e.preventDefault()
        setDragging(false)
    }
    const fileDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const file = e.dataTransfer.files[0];
        const read = (blob) => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = (event) => resolve(event.target.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
        toast.info('Reading file...')
        read(file).then(result => {
            if (file.size > maxSize) {
                toast.error(`File size should not exceed ${maxSize / 1024 / 1024}MB`)
                return
            }
            if (!types.includes(file.type)) {
                toast.error(`File type not supported. Supported types are ${types.join(', ')}`)
                return
            }
            setUploadedFile({ file: file })
            onDrop({ file: file, src: result })
        }
        )
    }



    const handleBrowse = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const file = e.target.files[0];
        const read = (blob) => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = (event) => resolve(event.target.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
        read(file).then(result => {
            if (file.size > maxSize) {
                toast.error(`File size should not exceed ${maxSize / 1024 / 1024}MB`)
                return
            }
            if (!types.includes(file.type)) {
                toast.error(`File type not supported. Supported types are ${types.join(', ')}`)
                return
            }
            setUploadedFile({ file: file })
            toast.success('File read successfully')
            onDrop({ file: file, src: result })
        })
    }



    return (<Center id='dragging-zone-part' borderWidth="1px" borderRadius="lg" px="6" py="4" {...props}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}

        borderColor={dragging ? colorMode === 'light' ? 'brand.200' : 'brand.300' : 'brand.200'}
        color={dragging ? colorMode === 'light' ? 'brand.500' : 'brand.400' : 'brand.500'}
    >
        <VStack spacing="3">
            <Square size="10" borderRadius="lg">
                <Icon as={FiUploadCloud} boxSize="5" color="fg.muted" />
            </Square>
            <VStack spacing="1">
                <Text fontSize="sm" color="fg.muted">Drag and drop your file here</Text>
                <HStack spacing="1">
                    <Text fontSize="sm" color="fg.muted">or</Text>
                    <Button size="sm" colorScheme="brand" variant="link"
                        onClick={() => document.getElementById('file_browser').click()}

                    >Browse</Button>
                </HStack>
                <Input id='file_browser' type="file" display="none" onChange={handleBrowse} />
            </VStack>
            {uploaded_file && <Divider my={2} />}
            {uploaded_file && <Text fontSize="sm" color="fg.muted">{uploaded_file?.file?.name}</Text>}
            {uploaded_file && <Text fontSize="sm" color="fg.muted">{Math.round(((((uploaded_file?.file?.size / 1024) / 1024) + Number.EPSILON)) * 100) / 100} Mbs</Text>}
        </VStack>
    </Center>
    )
}

export default Dropzone;