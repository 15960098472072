import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import store from "./redux/store";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { themeUPM } from './theme';
const root = ReactDOM.createRoot(document.getElementById('root'));
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  measurementId: "G-Y3REJNQ540",
};
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

function requestPermission() {
  try {
    // [START messaging_request_permission]
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        try {
          getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY }).then((currentToken) => {
            if (currentToken) {
              console.log('current token for client: ', currentToken);
            } else {
              // Show permission request UI
              console.log('No registration token available. Request permission to generate one.');
              // ...
            }
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // ...
          });
        } catch (error) {
          console.log(error)
        }

      } else {
        console.log('Unable to get permission to notify.');
      }
    }
    );
  } catch (error) {
    console.log(error)
  }

}
requestPermission();





root.render(
  <React.StrictMode>

    <Provider store={store}>
      <ChakraProvider theme={themeUPM}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ChakraProvider>

    </Provider>



  </React.StrictMode>

);




reportWebVitals();
