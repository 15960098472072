import {

  FETCH_PAYLOAD_ITEMS_SUCCESS,
  FETCH_PAYLOAD_ITEMS_STARTED,
  FETCH_PAYLOAD_ITEMS_FAILURE,

  FETCH_PAYLOAD_ITEM_STARTED,
  FETCH_PAYLOAD_ITEM_SUCCESS,
  FETCH_PAYLOAD_ITEM_FAILURE,

  ADD_PAYLOAD_ITEM_SUCCESS,
  ADD_PAYLOAD_ITEM_FAILURE,
  UPDATE_PAYLOAD_ITEM_FAILURE,
  UPDATE_PAYLOAD_ITEM_SUCCESS,
  DELETE_PAYLOAD_ITEM_SUCCESS,
  DELETE_PAYLOAD_ITEM_FAILURE,
  RESET_PAYLOAD_ITEM_SELECTED,
  DELETE_PAYLOAD_ITEM_STARTED,
  FETCH_PAYLOAD_ITEMS_PENDING_BY_TRAVELER_STARTED,
  FETCH_PAYLOAD_ITEMS_PENDING_BY_TRAVELER_SUCCESS,
  FETCH_PAYLOAD_ITEMS_PENDING_BY_TRAVELER_FAILURE,
  ADD_PAYLOAD_ITEM_STARTED,

} from "../actions/types";

const initialState = {
  payloadItems: [],
  pendingByTravelers: [],
  paginate: {},
  selected: null,
  loading: false,
  error: null
};

const payloadItemReducer = (state = initialState, action) => {
  switch (action.type) {
    //FETCH

    case FETCH_PAYLOAD_ITEMS_STARTED:
      return {
        ...state,
        paginate: {},
        error: null,
        loading: true
      };
    case FETCH_PAYLOAD_ITEMS_SUCCESS:
      return {
        ...state,
        payloadItems: action.payload.payloaditems,
        paginate: action.payload.paginate,
        loading: false,
        error: null
      };

    case FETCH_PAYLOAD_ITEMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    //FETCH PENDING BY TRAVELER
    case FETCH_PAYLOAD_ITEMS_PENDING_BY_TRAVELER_STARTED:
      return {
        ...state,
        pendingByTravelers: [],
        paginate: {},
        error: null,
        loading: true
      };
    case FETCH_PAYLOAD_ITEMS_PENDING_BY_TRAVELER_SUCCESS:
      return {
        ...state,
        pendingByTravelers: action.payload.payloaditems,
        paginate: action.payload.paginate,
        loading: false,
        error: null
      };

    case FETCH_PAYLOAD_ITEMS_PENDING_BY_TRAVELER_FAILURE:
      return {
        ...state,
        loading: false,
        pendingByTravelers: [],
        error: action.payload.error
      };





    //FETCH ITEM

    case FETCH_PAYLOAD_ITEM_STARTED:
      return {
        ...state,
        loading: true,
        selected: null,
      };
    case FETCH_PAYLOAD_ITEM_SUCCESS:

      return {
        ...state,
        selected: action.payload.payloadItem,
        loading: false,
        error: null
      };

    case FETCH_PAYLOAD_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        selected: null,
        error: action.payload.error
      };

    //RESTE
    case RESET_PAYLOAD_ITEM_SELECTED: {
      return {
        ...state,
        loading: false,
        selected: null,
      };
    }



    //ADD
case ADD_PAYLOAD_ITEM_STARTED:
      return {
        ...state,
        loading: true,
      };

    case ADD_PAYLOAD_ITEM_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        success: action.payload.successMessage
      };

    case ADD_PAYLOAD_ITEM_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        success: null

      };


    //DELETE

    case DELETE_PAYLOAD_ITEM_STARTED:
      return {
        ...state,
        loading: true,
      };


    case DELETE_PAYLOAD_ITEM_SUCCESS:
      return {
        ...state,
        payloadItems: state.payloadItems.filter(item => item._id !== action.payload.id),
        error: null,
        success: action.payload.successMessage,
        loading: false,
      };


    case DELETE_PAYLOAD_ITEM_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null,
        loading: false,
      };


    //UPDATE
    case UPDATE_PAYLOAD_ITEM_SUCCESS:
      return {
        ...state,
        error: null,
        success: action.payload.successMessage,
        loading: false,
      };


    case UPDATE_PAYLOAD_ITEM_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null
      };

    default:
      return state;
  }
};

export default payloadItemReducer;
