/**
 * This function to Setup config/headers and token
 * it gets the token from our main state and attach it to the header
 */
import { getAuth } from "firebase/auth";
/**
 * 
 * @param {*} getState 
 * @param {*} optionalParams 
 * @param {*} user 
 * @param {*} headers 
 * @returns 
 */
export const tokenConfig = async (
  optionalParams = null,
  headers = { "Content-type": "application/json" }) => {
  // Get token from localstorage

  const token = await getAuth()?.currentUser?.getIdToken();

  const config = {
    headers: headers,
    params: {}
  };

  // If token, add to headers
  if (token) {
    config.headers["x-auth-token"] = token;
  }
  
  if (optionalParams) {
    config.params = optionalParams;
  }

  return config;
};
