import { UploadImageForm } from "@components/uploadImage";
import axios from "axios";

const { Box, Container, Stack, Button, Text } = require("@chakra-ui/react");
const { tokenConfig } = require("@redux/actions/auth-actions/tokenConfig");

const { useState } = require("react");
const { toast } = require("react-toastify");


const UploadFilesPage = () => {

    const [file, setFile] = useState(null);
    const handleUploadFile = async () => {
        try {
            const token = await tokenConfig({
                action: 'upload_file',
            },
                {
                    'Content-type': 'multipart/form-data'
                }
            );
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileName', file.name);
            toast.promise(axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/files/upload`, formData, token), {
                loading: 'Uploading file...',
                success: 'File uploaded successfully',
                error: 'Failed to upload file'
            })

        } catch (error) {
            console.log(error)

            toast.error( 'An error occurred')
        }
    }



    return (<Box>
        <Container>
           <UploadImageForm image={file} onImageReady={(image) => setFile(image.file)} />
            <Stack>
                <Button onClick={handleUploadFile}>Upload File</Button>
            </Stack>

        </Container>
    </Box>

    );
};

export default UploadFilesPage;