


/**************  <COMPANY TYPES> **********************************/
export const FETCH_COMPANY_STARTED = "FETCH_COMPANY_STARTED";
export const FETCH_COMPANY_FAILURE = "FETCH_COMPANY_FAILURE";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";


export const FETCH_COMPANIES_STARTED = "FETCH_COMPANIES_STARTED";
export const FETCH_COMPANIES_FAILURE = "FETCH_COMPANIES_FAILURE";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";


export const RESET_COMPANY_SELECTED = "RESET_COMPANY_SELECTED";

export const SEARCH_COMPANY_STARTED = "SEARCH_COMPANY_STARTED";
export const SEARCH_COMPANY_FAILURE = "SEARCH_COMPANY_FAILURE";
export const SEARCH_COMPANY_SUCCESS = "SEARCH_COMPANY_SUCCESS";


export const ADD_COMPANY_STARTED = " ADD_COMPANY_STARTED";
export const ADD_COMPANY_SUCCESS = " ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAILURE = "ADD_COMPANY_FAILURE";



export const UPDATE_COMPANY_STARTED = "UPDATE_COMPANY_STARTED";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";


export const DELETE_COMPANY_STARTED = "DELETE_COMPANY_STARTED";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";
/****************</ COMPANY TYPES> */



/**************  <PART TYPES> **********************************/
export const FETCH_PARTS_STARTED = "FETCH_PARTS_STARTED";
export const FETCH_PARTS_FAILURE = "FETCH_PARTS_FAILURE";
export const FETCH_PARTS_SUCCESS = "FETCH_PARTS_SUCCESS";

export const FETCH_PART_STARTED = "FETCH_PART_STARTED";
export const FETCH_PART_FAILURE = "FETCH_PART_FAILURE";
export const FETCH_PART_SUCCESS = "FETCH_PART_SUCCESS";

export const FETCH_PART_TYPES_STARTED = 'FETCH_PART_TYPES_STARTED';
export const FETCH_PART_TYPES_SUCCESS = 'FETCH_PART_TYPES_SUCCESS';
export const FETCH_PART_TYPES_FAILURE = 'FETCH_PART_TYPES_FAILURE';


//RESET
export const RESET_PART_SELECTED = "RESET_PART_SELECTED";


//SEARCH
export const SEARCH_PART_STARTED = "SEARCH_PART_STARTED";
export const SEARCH_PART_FAILURE = "SEARCH_PART_FAILURE";
export const SEARCH_PART_SUCCESS = "SEARCH_PART_SUCCESS";



// Add new Part
export const ADD_PART_SUCCESS = " ADD_PART_SUCCESS";
export const ADD_PART_FAILURE = "ADD_PART_FAILURE";


// Update Part
export const UPDATE_PART_SUCCESS = "UPDATE_PART_SUCCESS";
export const UPDATE_PART_FAILURE = "UPDATE_PART_FAILURE";

// Delete Part
export const DELETE_PART_SUCCESS = "DELETE_PART_SUCCESS";
export const DELETE_PART_FAILURE = "DELETE_PRODUCT_FAILURE";

/**************  </PART TYPES> **********************************/





/**************  <DEPARTMENT TYPES> **********************************/
export const FETCH_DEPARTMENT_STARTED = "FETCH_DEPARTMENT_STARTED";
export const FETCH_DEPARTMENT_FAILURE = "FETCH_DEPARTMENT_FAILURE";
export const FETCH_DEPARTMENT_SUCCESS = "FETCH_DEPARTMENT_SUCCESS";
export const FETCH_DEPARTMENTS_STARTED = "FETCH_DEPARTMENTS_STARTED";
export const FETCH_DEPARTMENTS_FAILURE = "FETCH_DEPARTMENTS_FAILURE";
export const FETCH_DEPARTMENTS_SUCCESS = "FETCH_DEPARTMENTS_SUCCESS";

// Add new Department
export const ADD_DEPARTMENT_SUCCESS = " ADD_DEPARTMENT_SUCCESS";
export const ADD_DEPARTMENT_FAILURE = "ADD_DEPARTMENT_FAILURE";


// Update Department
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_FAILURE = "UPDATE_DEPARTMENT_FAILURE";

// Delete Department
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_FAILURE = "DELETE_PRODUCT_FAILURE";

/**************  </DEPARTMENT TYPES> **********************************/



// Fetch Payload 
export const FETCH_PAYLOAD_ITEM_STARTED = "FETCH_PAYLOAD_ITEMT_STARTED";
export const FETCH_PAYLOAD_ITEM_FAILURE = "FETCH_PAYLOAD_ITEM_FAILURE";
export const FETCH_PAYLOAD_ITEM_SUCCESS = "FETCH_PAYLOAD_ITEM_SUCCESS";

export const FETCH_PAYLOAD_ITEMS_STARTED = "FETCH_PAYLOAD_ITEMS_STARTED";
export const FETCH_PAYLOAD_ITEMS_FAILURE = "FETCH_PAYLOAD_ITEMS_FAILURE";
export const FETCH_PAYLOAD_ITEMS_SUCCESS = "FETCH_PAYLOAD_ITEMS_SUCCESS";

//Fetch Payload Items Pending By Traveler
export const FETCH_PAYLOAD_ITEMS_PENDING_BY_TRAVELER_STARTED = "FETCH_PAYLOAD_ITEMS_PENDING_BY_TRAVELER_STARTED";
export const FETCH_PAYLOAD_ITEMS_PENDING_BY_TRAVELER_SUCCESS = "FETCH_PAYLOAD_ITEMS_PENDING_BY_TRAVELER_SUCCESS";
export const FETCH_PAYLOAD_ITEMS_PENDING_BY_TRAVELER_FAILURE = "FETCH_PAYLOAD_ITEMS_PENDING_BY_TRAVELER_FAILURE";



// Add new PAYLOAD_ITEM
export const ADD_PAYLOAD_ITEM_SUCCESS = " ADD_PAYLOAD_ITEM_SUCCESS";
export const ADD_PAYLOAD_ITEM_FAILURE = "ADD_PAYLOAD_ITEM_FAILURE";
export const ADD_PAYLOAD_ITEM_STARTED = "ADD_PAYLOAD_ITEM_STARTED";


// Update PAYLOAD_ITEM
export const UPDATE_PAYLOAD_ITEM_SUCCESS = "UPDATE_PAYLOAD_ITEM_SUCCESS";
export const UPDATE_PAYLOAD_ITEM_FAILURE = "UPDATE_PAYLOAD_ITEM_FAILURE";

// Delete PAYLOAD_ITEM
export const DELETE_PAYLOAD_ITEM_STARTED = "DELETE_PAYLOAD_ITEM_STARTED";
export const DELETE_PAYLOAD_ITEM_SUCCESS = "DELETE_PAYLOAD_ITEM_SUCCESS";
export const DELETE_PAYLOAD_ITEM_FAILURE = "DELETE_PRODUCT_FAILURE";

//RESET PAYLOAD ITEM SELECTED
export const RESET_PAYLOAD_ITEM_SELECTED = "RESET_PAYLOAD_ITEM_SELECTED";








// Fetch SALE 
export const FETCH_SALE_ORDER_STARTED = "FETCH_SALE_ORDERT_STARTED";
export const FETCH_SALE_ORDER_FAILURE = "FETCH_SALE_ORDER_FAILURE";
export const FETCH_SALE_ORDER_SUCCESS = "FETCH_SALE_ORDER_SUCCESS";

export const FETCH_SALE_ORDERS_STARTED = "FETCH_SALE_ORDERS_STARTED";
export const FETCH_SALE_ORDERS_FAILURE = "FETCH_SALE_ORDERS_FAILURE";
export const FETCH_SALE_ORDERS_SUCCESS = "FETCH_SALE_ORDERS_SUCCESS";

//SEARCH  SALE ORDER
export const SEARCH_SALE_ORDER_STARTED = "SEARCH_SALE_ORDER_STARTED";
export const SEARCH_SALE_ORDER_FAILURE = "SEARCH_SALE_ORDER_FAILURE";
export const SEARCH_SALE_ORDER_SUCCESS = "SEARCH_SALE_ORDER_SUCCESS";


// Add new SALE_ORDER
export const ADD_SALE_ORDER_SUCCESS = " ADD_SALE_ORDER_SUCCESS";
export const ADD_SALE_ORDER_FAILURE = "ADD_SALE_ORDER_FAILURE";


// Update SALE_ORDER
export const UPDATE_SALE_ORDER_STARTED = "UPDATE_SALE_ORDER_STARTED"
export const UPDATE_SALE_ORDER_SUCCESS = "UPDATE_SALE_ORDER_SUCCESS";
export const UPDATE_SALE_ORDER_FAILURE = "UPDATE_SALE_ORDER_FAILURE";

// Delete SALE_ORDER
export const DELETE_SALE_ORDER_SUCCESS = "DELETE_SALE_ORDER_SUCCESS";
export const DELETE_SALE_ORDER_FAILURE = "DELETE_SALE_ORDER_FAILURE";

//RESET
export const RESET_SALE_ORDER_SELECTED = "RESET_SALE_ORDER_SELECTED"





//Fetch STOCK ITEMS
export const FETCH_STOCK_ITEMS_STARTED = "FETCH_STOCK_ITEMS_STARTED";
export const FETCH_STOCK_ITEMS_SUCCESS = "FETCH_STOCK_ITEMS_SUCCESS";
export const FETCH_STOCK_ITEMS_FAILURE = "FETCH_STOCK_ITEMS_FAILURE";

//Fetch STOCK ITEM
export const FETCH_STOCK_ITEM_STARTED = "FETCH_STOCK_ITEM_STARTED";
export const FETCH_STOCK_ITEM_SUCCESS = "FETCH_STOCK_ITEM_SUCCESS";
export const FETCH_STOCK_ITEM_FAILURE = "FETCH_STOCK_ITEM_FAILURE";

//Fetch STOCK ITEMS BY PART
export const FETCH_STOCK_ITEMS_BY_PART_STARTED = "FETCH_STOCK_ITEMS_BY_PART_STARTED";
export const FETCH_STOCK_ITEMS_BY_PART_SUCCESS = "FETCH_STOCK_ITEMS_BY_PART_SUCCESS";
export const FETCH_STOCK_ITEMS_BY_PART_FAILURE = "FETCH_STOCK_ITEMS_BY_PART_FAILURE";



// Add new STOCK ITEM
export const ADD_STOCK_ITEM_SUCCESS = " ADD_STOCK_ITEM_SUCCESS";
export const ADD_STOCK_ITEM_FAILURE = "ADD_STOCK_ITEM_FAILURE";

// Update STOCK ITEM
export const UPDATE_STOCK_ITEM_STARTED = "UPDATE_STOCK_ITEM_STARTED";
export const UPDATE_STOCK_ITEM_SUCCESS = "UPDATE_STOCK_ITEM_SUCCESS";
export const UPDATE_STOCK_ITEM_FAILURE = "UPDATE_STOCK_ITEM_FAILURE";

// Delete STOCK ITEM
export const DELETE_STOCK_ITEM_SUCCESS = "DELETE_STOCK_ITEM_SUCCESS";
export const DELETE_STOCK_ITEM_FAILURE = "DELETE_STOCK_ITEM_FAILURE";

//RESET STOCK ITEM SELECTED
export const RESET_STOCK_ITEM_SELECTED = "RESET_STOCK_ITEM_SELECTED";

//SEARCH STOCK ITEM
export const SEARCH_STOCK_ITEM_STARTED = "SEARCH_STOCK_ITEM_STARTED";
export const SEARCH_STOCK_ITEM_FAILURE = "SEARCH_STOCK_ITEM_FAILURE";
export const SEARCH_STOCK_ITEM_SUCCESS = "SEARCH_STOCK_ITEM_SUCCESS";





// Fetch TRAVELER 
export const FETCH_TRAVELER_STARTED = "FETCH_TRAVELERT_STARTED";
export const FETCH_TRAVELER_FAILURE = "FETCH_TRAVELER_FAILURE";
export const FETCH_TRAVELER_SUCCESS = "FETCH_TRAVELER_SUCCESS";
export const FETCH_TRAVELERS_STARTED = "FETCH_TRAVELERS_STARTED";
export const FETCH_TRAVELERS_FAILURE = "FETCH_TRAVELERS_FAILURE";


//Fetch Transfer Travelers
export const FETCH_TRANSFER_TRAVELERS_STARTED = "FETCH_TRANSFER_TRAVELERS_STARTED";
export const FETCH_TRANSFER_TRAVELERS_SUCCESS = "FETCH_TRANSFER_TRAVELERS_SUCCESS";
export const FETCH_TRANSFER_TRAVELERS_FAILURE = "FETCH_TRANSFER_TRAVELERS_FAILURE";


export const FETCH_TRAVELERS_SUCCESS = "FETCH_TRAVELERS_SUCCESS";

// Add new TRAVELER
export const ADD_TRAVELER_SUCCESS = " ADD_TRAVELER_SUCCESS";
export const ADD_TRAVELER_FAILURE = "ADD_TRAVELER_FAILURE";


// Update TRAVELER
export const UPDATE_TRAVELER_SUCCESS = "UPDATE_TRAVELER_SUCCESS";
export const UPDATE_TRAVELER_FAILURE = "UPDATE_TRAVELER_FAILURE";


// Track TRAVELER
export const TRACK_TRAVELER_STARTED = "TRACK_TRAVELER_STARTED";
export const TRACK_TRAVELER_SUCCESS = "TRACK_TRAVELER_SUCCESS";
export const TRACK_TRAVELER_FAILURE = "TRACK_TRAVELER_FAILURE";


// Delete TRAVELER
export const DELETE_TRAVELER_SUCCESS = "DELETE_TRAVELER_SUCCESS";
export const DELETE_TRAVELER_FAILURE = "DELETE_TRAVELER_FAILURE";







// users options
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";



// fetch User Managers
export const FETCH_MANAGERS_STARTED = "FETCH_MANAGERS_STARTED";
export const FETCH_MANAGERS_SUCCESS = "FETCH_MANAGERS_SUCCESS";
export const FETCH_MANAGERS_FAILURE = "FETCH_MANAGERS_FAILURE";







// add product to cart
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILURE = "ADD_TO_CART_FAILURE";

// change quantity from cart
export const CHANGE_CART_QUANTITY_SUCCESS = "CHANGE_CART_QUANTITY_SUCCESS";
export const CHANGE_CART_QUANTITY_FAILURE = "CHANGE_CART_QUANTITY_FAILURE";

// delete product from cart
export const DELETE_FROM_CART_SUCCESS = "DELETE_FROM_CART_SUCCESS";
export const DELETE_FROM_CART_FAILURE = "DELETE_FROM_CART_FAILURE";

// fetch All user Addresses
export const FETCH_ADDRESSES_STARTED = "FETCH_ADDRESSES_STARTED";
export const FETCH_ADDRESSES_SUCCESS = "FETCH_ADDRESSES_SUCCESS";
export const FETCH_ADDRESSES_FAILURE = "FETCH_ADDRESSES_FAILURE";

// add a new address
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAILURE = "ADD_ADDRESS_FAILURE";

// delete an address
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAILURE = "DELETE_ADDRESS_FAILURE";

// update an address
export const EDIT_ADDRESS_SUCCESS = "EDIT_ADDRESS_SUCCESS";
export const EDIT_ADDRESS_FAILURE = "EDIT_ADDRESS_FAILURE";

// fetch WISHLIST products
export const FETCH_WISHLIST_STARTED = "FETCH_WISHLIST_STARTED";
export const FETCH_WISHLIST_SUCCESS = "FETCH_WISHLIST_SUCCESS";
export const FETCH_WISHLIST_FAILURE = "FETCH_WISHLIST_FAILURE";

// add product to WISHLIST
export const ADD_TO_WISHLIST_SUCCESS = "ADD_TO_WISHLIST_SUCCESS";
export const ADD_TO_WISHLIST_FAILURE = "ADD_TO_WISHLIST_FAILURE";

// delete product from WISHLIST
export const DELETE_FROM_WISHLIST_SUCCESS = "DELETE_FROM_WISHLIST_SUCCESS";
export const DELETE_FROM_WISHLIST_FAILURE = "DELETE_FROM_WISHLIST_FAILURE";

// fetch All user orders history
export const FETCH_HISTORY_STARTED = "FETCH_HISTORY_STARTED";
export const FETCH_HISTORY_SUCCESS = "FETCH_HISTORY_SUCCESS";
export const FETCH_HISTORY_FAILURE = "FETCH_HISTORY_FAILURE";

// fetch All seller's orders to ship
export const FETCH_ORDERS_TO_SHIP_STARTED = "FETCH_ORDERS_TO_SHIP_STARTED";
export const FETCH_ORDERS_TO_SHIP_SUCCESS = "FETCH_ORDERS_TO_SHIP_SUCCESS";
export const FETCH_ORDERS_TO_SHIP_FAILURE = "FETCH_ORDERS_TO_SHIP_FAILURE";

// fetch All seller's shipped orders
export const FETCH_SHIPPED_ORDERS_STARTED = "FETCH_SHIPPED_ORDERS_STARTED";
export const FETCH_SHIPPED_ORDERS_SUCCESS = "FETCH_SHIPPED_ORDERS_SUCCESS";
export const FETCH_SHIPPED_ORDERS_FAILURE = "FETCH_SHIPPED_ORDERS_FAILURE";

// Mark order as shipped
export const ORDER_SHIPPED_SUCCESS = "ORDER_SHIPPED_SUCCESS";
export const ORDER_SHIPPED_FAILURE = "ORDER_SHIPPED_SUCCESS";

// order success
export const PLACED_ORDER_SUCCESS = "PLACED_ORDER_SUCCESS";
export const PLACED_ORDER_FAILURE = "PLACED_ORDER_FAILURE";

// Choose order address
export const CHOOSE_ORDER_ADDRESS_SUCCESS = "CHOOSE_ORDER_ADDRESS_SUCCESS";
export const CHOOSE_ORDER_ADDRESS_FAILURE = "CHOOSE_ORDER_ADDRESS_SUCCESS";

// users options
export const FETCH_ALL_USERS_STARTED = "FETCH_ALL_USERS_STARTED";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const FETCH_ALL_USERS_FAILURE = "FETCH_ALL_USERS_FAILURE";

// change user permissions to admin
export const ADMIN_PERMISSION_SUCCESS = "ADMIN_PERMISSION_SUCCESS";
export const ADMIN_PERMISSION_FAILURE = "ADMIN_PERMISSION_FAILURE";

// change user permissions to shipper
export const SHIPPER_PERMISSION_SUCCESS = "SHIPPER_PERMISSION_SUCCESS";
export const SHIPPER_PERMISSION_FAILURE = "SHIPPER_PERMISSION_FAILURE";

// restrict user from all permissions
export const STRICT_USER_SUCCESS = "STRICT_USER_SUCCESS";
export const STRICT_USER_FAILURE = "STRICT_USER_FAILURE";

// fetch shippers
export const FETCH_SHIPPERS_STARTED = "FETCH_SHIPPERS_STARTED";
export const FETCH_SHIPPERS_SUCCESS = "FETCH_SHIPPERS_SUCCESS";
export const FETCH_SHIPPERS_FAILURE = "FETCH_SHIPPERS_FAILURE";

// edit shipper
export const EDIT_SHIPPERS_SUCCESS = "EDIT_SHIPPERS_SUCCESS";
export const EDIT_SHIPPERS_FAILURE = "EDIT_SHIPPERS_FAILURE";

// fetch All shipper orders to deliver
export const FETCH_ORDERS_TO_DELIVER_STARTED = "FETCH_ORDERS_TO_DELIVER_STARTED";
export const FETCH_ORDERS_TO_DELIVER_SUCCESS = "FETCH_ORDERS_TO_DELIVER_SUCCESS";
export const FETCH_ORDERS_TO_DELIVER_FAILURE = "FETCH_ORDERS_TO_DELIVER_FAILURE";

// Mark order as delivered
export const ORDER_DELIVERED_SUCCESS = "ORDER_DELIVERED_SUCCESS";
export const ORDER_DELIVERED_FAILURE = "ORDER_DELIVERED_SUCCESS";


//REPORTS
export const FETCH_NCREPORTS_STARTED = "FETCH_NCREPORTS_STARTED";
export const FETCH_NCREPORTS_FAILURE = "FETCH_NCREPORTS_FAILURE";
export const FETCH_NCREPORTS_SUCCESS = "FETCH_NCREPORTS_SUCCESS";

export const FETCH_NCREPORT_STARTED = "FETCH_NCREPORT_STARTED";
export const FETCH_NCREPORT_FAILURE = "FETCH_NCREPORT_FAILURE";
export const FETCH_NCREPORT_SUCCESS = "FETCH_NCREPORT_SUCCESS";

//ADD
export const ADD_NCREPORT_STARTED = "ADD_NCREPORT_STARTED";
export const ADD_NCREPORT_SUCCESS = "ADD_NCREPORT_SUCCESS";
export const ADD_NCREPORT_FAILURE = "ADD_NCREPORT_FAILURE";

//UPDATE
export const UPDATE_NCREPORT_STARTED = "UPDATE_NCREPORT_STARTED";
export const UPDATE_NCREPORT_SUCCESS = "UPDATE_NCREPORT_SUCCESS";
export const UPDATE_NCREPORT_FAILURE = "UPDATE_NCREPORT_FAILURE";

//DELETE
export const DELETE_NCREPORT_STARTED = "DELETE_NCREPORT_STARTED";
export const DELETE_NCREPORT_SUCCESS = "DELETE_NCREPORT_SUCCESS";
export const DELETE_NCREPORT_FAILURE = "DELETE_NCREPORT_FAILURE";

//SEARCH
export const SEARCH_NCREPORT_STARTED = "SEARCH_NCREPORT_STARTED";
export const SEARCH_NCREPORT_FAILURE = "SEARCH_NCREPORT_FAILURE";
export const SEARCH_NCREPORT_SUCCESS = "SEARCH_NCREPORT_SUCCESS";





//RESET
export const RESET_NCREPORT_SELECTED = "RESET_NCREPORT_SELECTED";




export const TABLE_VIEW = "TABLE_VIEW";
export const GRID_VIEW = "GRID_VIEW";
