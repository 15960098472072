import {

  FETCH_TRAVELERS_SUCCESS,
  FETCH_TRAVELERS_STARTED,
  FETCH_TRAVELERS_FAILURE,
  ADD_TRAVELER_SUCCESS,
  ADD_TRAVELER_FAILURE,
  UPDATE_TRAVELER_FAILURE,
  UPDATE_TRAVELER_SUCCESS,
  DELETE_TRAVELER_SUCCESS,
  DELETE_TRAVELER_FAILURE,
  FETCH_TRAVELER_STARTED,
  FETCH_TRAVELER_SUCCESS,
  FETCH_TRAVELER_FAILURE,
  TRACK_TRAVELER_SUCCESS,
  TRACK_TRAVELER_FAILURE,
  TRACK_TRAVELER_STARTED,

} from "../actions/types";

const initialState = {
  travelers: [],
  paginate: {},
  selected: null,
  isSearching: false,
  isTracking: false,
  loading: false,
  error: null
};

const travelerReducer = (state = initialState, action) => {
  switch (action.type) {
    //FETCH

    case FETCH_TRAVELERS_STARTED:
      return {
        ...state,
        paginate: {},
        travelers: [],
        selected: null,
        loading: true
      };
    case FETCH_TRAVELERS_SUCCESS:
      return {
        ...state,
        travelers: action.payload.travelers,
        loading: false,
        paginate: action.payload.paginate,
        error: null
      };

    case FETCH_TRAVELERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };




    //SELECT
    case FETCH_TRAVELER_STARTED:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case FETCH_TRAVELER_SUCCESS:
      return {
        ...state,
        selected: action.payload.traveler,
        loading: false,
        error: null
      };

    case FETCH_TRAVELER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };


    //ADD
    case ADD_TRAVELER_SUCCESS:
      return {
        ...state,
        error: null,
        success: action.payload.successMessage
      };

    case ADD_TRAVELER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null
      };


    //track
    case TRACK_TRAVELER_STARTED:
      return {
        ...state,
        error: null,
        success: null,
        isTracking: true,
      };

    case TRACK_TRAVELER_SUCCESS:
      return {
        ...state,
        error: null,
        success: action.payload.successMessage,
        isTracking: false,
      };

    case TRACK_TRAVELER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null,
        isTracking: false,
      };


    //DELETE
    case DELETE_TRAVELER_SUCCESS:
      return {
        ...state,
        travelers: state.travelers.filter(traveler => traveler._id !== action.payload.id),
        error: null,
        success: action.payload.successMessage
      };




    case DELETE_TRAVELER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null
      };


    //UPDATE
    case UPDATE_TRAVELER_SUCCESS:
      return {
        ...state,
        travelers: state.travelers.map(traveler => {
          if (traveler && traveler._id === action.payload.traveler._id) {
            console.log('old Traveler found')
            return action.payload.traveler;
          }
          return traveler;
        }),
        error: null,
        success: action.payload.successMessage
      };


    case UPDATE_TRAVELER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null
      };

    default:
      return state;
  }
};

export default travelerReducer;
