import {

  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_STARTED,
  FETCH_COMPANIES_FAILURE,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_STARTED,
  FETCH_COMPANY_FAILURE,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAILURE,
  UPDATE_COMPANY_FAILURE,
  UPDATE_COMPANY_SUCCESS,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILURE,
  DELETE_COMPANY_STARTED,
  ADD_COMPANY_STARTED,
  SEARCH_COMPANY_STARTED,
  SEARCH_COMPANY_SUCCESS,
  SEARCH_COMPANY_FAILURE,
  RESET_COMPANY_SELECTED,

} from "../actions/types";

const initialState = {
  companies: [],
  loading: false,
  error: null
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    //FETCH
    case FETCH_COMPANIES_STARTED:
      return {
        ...state,
        loading: true,
        companies: [],
      };
    case FETCH_COMPANIES_SUCCESS:

      return {
        ...state,
        companies: action.payload.companies,
        paginate: action.payload.paginate,
        loading: false,
        error: null
      };

    case FETCH_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };


    //FETCH A cOMPANY
    case FETCH_COMPANY_STARTED:
      return {
        ...state,
        loading: true,
        selected: null,
      };
    case FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        selected: action.payload.company,
        loading: false,
        error: null
      };

    case FETCH_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };


    case RESET_COMPANY_SELECTED: {
      return {
        ...state,
        loading: false,
        selected: null,
      };
    }



    //SEARCH
    case SEARCH_COMPANY_STARTED:
      return {
        ...state,
        isSearching: true,
        
      };
    case SEARCH_COMPANY_SUCCESS:

      return {
        ...state,
        companies: action.payload.list,
        paginate: action.payload.paginate,
        isSearching: false,
        error: null
      };

    case SEARCH_COMPANY_FAILURE:
      return {
        ...state,
        isSearching: false,
        error: action.payload.error
      };





    //ADD
    case ADD_COMPANY_STARTED:
      return {
        ...state,
        loading: true
      };
    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        companies: [action.payload.company, ...state.companies],
        error: null,
        success: action.payload.successMessage,
        loading: false
      };

    case ADD_COMPANY_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null,
        loading: false
      };


    //DELETE

    case DELETE_COMPANY_STARTED:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: state.companies.filter(compy => compy._id !== action.payload.id),
        error: null,
        success: action.payload.successMessage,
        loading: false,
      };




    case DELETE_COMPANY_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        success: null
      };


    //UPDATE

    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        error: null,
        success: action.payload.successMessage,
        loading: false,
      };


    case UPDATE_COMPANY_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null,
        loading: false
      };

    default:
      return state;
  }
};

export default companyReducer;
