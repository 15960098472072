import { FETCH_MANAGERS_FAILURE, FETCH_MANAGERS_STARTED, FETCH_MANAGERS_SUCCESS } from "../actions/types";
  
  const initialState = {
    managers: [],
    loading: false,
    error: null
  };
  
  const managerReducer = (state = initialState, action) => {
    switch (action.type) {
      //FETCH
        case FETCH_MANAGERS_STARTED:
          return {
            ...state,
            loading: true
          };
        case FETCH_MANAGERS_SUCCESS:
         
          return {
            ...state,
            managers: action.payload.managers,
            loading: false,
            error: null
          };
    
        case FETCH_MANAGERS_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload.error
          };
    
  
     
      default:
        return state;
    }
  };
  
  export default managerReducer;
  