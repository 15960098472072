import {

  FETCH_DEPARTMENTS_SUCCESS,
  FETCH_DEPARTMENTS_STARTED,
  FETCH_DEPARTMENTS_FAILURE,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_FAILURE,
  UPDATE_DEPARTMENT_FAILURE,
  UPDATE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAILURE,
  FETCH_DEPARTMENT_SUCCESS,
  FETCH_DEPARTMENT_STARTED,
  FETCH_DEPARTMENT_FAILURE,

} from "../actions/types";

const initialState = {
  departments: [],
  paginate: {},
  selected: null,
  loading: false,
  error: null
};

const departmentReducer = (state = initialState, action) => {
  switch (action.type) {
    //FETCH

    case FETCH_DEPARTMENTS_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_DEPARTMENTS_SUCCESS:

      return {
        ...state,
        departments: action.payload.departments,
        loading: false,
        error: null
      };

    case FETCH_DEPARTMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case FETCH_DEPARTMENT_STARTED:
      return {
        ...state,
        selected: null,
        paginate_activity: {},
        paginate: {},
        loading: true,
        error: null
      };

    case FETCH_DEPARTMENT_SUCCESS:
      return {
        ...state,
        selected: action.payload.department,
        paginate: action?.payload?.paginate,
        error: null,
        loading: false
      };

    case FETCH_DEPARTMENT_FAILURE:
      return {
        ...state,
        selected: null,
        loading: false,
        error: action.payload.error
      };



    //ADD
    case ADD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: [action.payload.department, ...state.departments],
        error: null,
        success: action.payload.successMessage
      };

    case ADD_DEPARTMENT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null
      };


    //DELETE
    case DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: state.departments.filter(department => department._id !== action.payload.id),
        error: null,
        success: action.payload.successMessage
      };




    case DELETE_DEPARTMENT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null
      };


    //UPDATE
    case UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: state.departments.map(department => {
          if (department && department._id === action.payload.department._id) {
            console.log('old Department found')
            return action.payload.department;
          }
          return department;
        }),
        error: null,
        success: action.payload.successMessage
      };


    case UPDATE_DEPARTMENT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null
      };

    default:
      return state;
  }
};

export default departmentReducer;
