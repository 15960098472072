import {

  FETCH_STOCK_ITEMS_SUCCESS,
  FETCH_STOCK_ITEMS_STARTED,
  FETCH_STOCK_ITEMS_FAILURE,
  ADD_STOCK_ITEM_SUCCESS,
  ADD_STOCK_ITEM_FAILURE,
  UPDATE_STOCK_ITEM_FAILURE,
  UPDATE_STOCK_ITEM_SUCCESS,
  DELETE_STOCK_ITEM_SUCCESS,
  DELETE_STOCK_ITEM_FAILURE,
  FETCH_STOCK_ITEM_STARTED,
  FETCH_STOCK_ITEM_SUCCESS,
  FETCH_STOCK_ITEM_FAILURE,
  RESET_STOCK_ITEM_SELECTED,
  SEARCH_STOCK_ITEM_STARTED,
  SEARCH_STOCK_ITEM_SUCCESS,
  SEARCH_STOCK_ITEM_FAILURE,
  UPDATE_STOCK_ITEM_STARTED,
  FETCH_STOCK_ITEMS_BY_PART_SUCCESS,
  FETCH_STOCK_ITEMS_BY_PART_FAILURE,
  FETCH_STOCK_ITEMS_BY_PART_STARTED,

} from "../actions/types";

const initialState = {
  stockitems: [],
  stockitemsByPart: [],
  isSearching: false,
  isUpdating: false,
  selected: null,
  loading: false,
  error: null,
  paginate: {},
};

const stockItemReducer = (state = initialState, action) => {
  switch (action.type) {
    //FETCH

    case FETCH_STOCK_ITEMS_STARTED:
      return {
        ...state,
        stockitems: [],
        loading: true
      };
    case FETCH_STOCK_ITEMS_SUCCESS:
      return {
        ...state,
        stockitems: action.payload.list,
        paginate: action.payload.paginate,
        loading: false,
        isSearching: false,
        error: null
      };

    case FETCH_STOCK_ITEMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case FETCH_STOCK_ITEMS_BY_PART_STARTED:
      return {
        ...state,
        stockitemsByPart: [],
        loading: true
      };
    case FETCH_STOCK_ITEMS_BY_PART_SUCCESS:
      return {
        ...state,
        stockitemsByPart: action.payload.list,
        paginate: action.payload.paginate,
        loading: false,
        isSearching: false,
        error: null
      };

    case FETCH_STOCK_ITEMS_BY_PART_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };



    case FETCH_STOCK_ITEM_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_STOCK_ITEM_SUCCESS:
      return {
        ...state,
        selected: action.payload.stockitem,
        loading: false,
        error: null
      };

    case FETCH_STOCK_ITEM_FAILURE:
      return {
        ...state,
        selected: null,
        loading: false,
        error: action.payload.error
      };


    //SEARCH
    case SEARCH_STOCK_ITEM_STARTED:
      return {
        ...state,
        isSearching: true,
        selected: null
      };
    case SEARCH_STOCK_ITEM_SUCCESS:

      return {
        ...state,
        stockitems: action.payload.list,
        paginate: action.payload.paginate,
        isSearching: false,
        error: null
      };

    case SEARCH_STOCK_ITEM_FAILURE:
      return {
        ...state,
        isSearching: false,
        error: action.payload.error
      };


    //RESET
    case RESET_STOCK_ITEM_SELECTED:
      return {
        ...state,
        selected: null,
      };




    //ADD
    case ADD_STOCK_ITEM_SUCCESS:
      return {
        ...state,
        stockitems: [action.payload.item, ...state.stockitems],
        error: null,
        success: action.payload.successMessage
      };

    case ADD_STOCK_ITEM_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null
      };


    //DELETE
    case DELETE_STOCK_ITEM_SUCCESS:
      return {
        ...state,
        error: null,
        success: action.payload.successMessage
      };


    case DELETE_STOCK_ITEM_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null
      };


    //UPDATE
    case UPDATE_STOCK_ITEM_STARTED:
      return {
        ...state,
        error: null,
        isUpdating: true,
      };

    case UPDATE_STOCK_ITEM_SUCCESS:
      return {
        ...state,
        error: null,
        isUpdating: false,
        success: action.payload.successMessage
      };


    case UPDATE_STOCK_ITEM_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isUpdating: false,
        success: null
      };

    default:
      return state;
  }
};

export default stockItemReducer;
